import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  DoBootstrap,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import '@arc-web/components/dist/arc';
import {
  provideTippyConfig,
  popperVariation,
  TippyDirective,
} from '@ngneat/helipopper';
import { ConfigService } from '@core/services/config.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TippyDirective,
  ],
  providers: [
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          arrow: true,
          placement: 'right',
          trigger: 'click',
          interactive: true,
        },
        popper: popperVariation,
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: ApplicationRef): void {
    ConfigService.load().then(() => {
      appRef.bootstrap(AppComponent);
    });
  }
}
