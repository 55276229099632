import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@material/material.module';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapSymbol } from '@core/models/map-symbol.model';
import { PopupData } from '@core/models/popup.model';

@Component({
  selector: 'map-popup',
  standalone: true,
  imports: [CommonModule, MaterialModule, NgxMapboxGLModule],
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() featureData!: PopupData;
  @Input() featureSymbol?: MapSymbol;
}
