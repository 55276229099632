<div class="layer-list">
  <div class="layer-list__header">
    <h1>Select layers to view</h1>
  </div>
  <div class="layer-list__category">
    <mat-accordion multi togglePosition="before">
      <mat-expansion-panel
        class="category__expansion"
        expanded="true"
        *ngFor="let cat of categories"
      >
        <mat-expansion-panel-header class="category__header">
          <mat-panel-title>
            <h2>{{ cat.displayName }}</h2>
          </mat-panel-title>
          <div class="category__button">
            <button
              mat-raised-button
              (click)="zoomTo(cat.mapSettings); $event.stopPropagation()"
            >
              Take Me There
              <mat-icon iconPositionEnd>east</mat-icon>
            </button>
          </div>
        </mat-expansion-panel-header>
        <div class="category__description">
          <p class="layer-list__description">{{ cat.description }}</p>
        </div>
        <mat-accordion multi togglePosition="before" class="layer">
          <mat-expansion-panel
            *ngFor="let layer of getLayers(cat.id); let i = index"
            [expanded]="layer.layout.visibility === 'visible'"
            class="layer__expansion"
          >
            <mat-expansion-panel-header class="layer__header">
              <div class="layer__label">
                <span id="layer-label-{{ i }}"> {{ layer.displayName }}</span>
                <mat-icon
                  *ngIf="layer.description"
                  [tp]="tpl"
                  (click)="$event.stopPropagation()"
                  aria-label="info icon that displays a tooltip when clicked"
                >
                  info_outline
                </mat-icon>
                <ng-template #tpl let-hide>
                  <div
                    class="rewild-tooltip"
                    [innerHTML]="layer.description"
                  ></div>
                </ng-template>
              </div>
              <mat-checkbox
                color="primary"
                [checked]="layer.layout.visibility === 'visible'"
                (change)="toggleVisibility(layer.id)"
                (click)="$event.stopPropagation()"
                aria-labelledby="layer-label-{{ i }}"
              >
              </mat-checkbox>
            </mat-expansion-panel-header>
            <map-legend
              [type]="layer.type"
              [paint]="layer.paint"
              [layout]="layer.layout"
              [mapSymbols]="mapSymbols"
            ></map-legend>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
