import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AnyLayer,
  AnyLayout,
  CirclePaint,
  Expression,
  FillPaint,
  LinePaint,
  SymbolLayout,
  SymbolPaint,
} from 'mapbox-gl';
import { MapSymbol } from '@core/models/map-symbol.model';

interface ColorData {
  label: string | number;
  value: string;
}

@Component({
  selector: 'map-legend',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {
  @Input() type!: AnyLayer['type']; // mapbox gl layer type
  @Input() paint?: CirclePaint | LinePaint | FillPaint | SymbolPaint;
  @Input() layout?: AnyLayout;
  @Input() mapSymbols?: MapSymbol[];

  paintColorString() {
    if (this.type === 'circle') {
      const paint = this.paint as CirclePaint;
      return typeof paint['circle-color'] === 'string' ? true : false;
    } else if (this.type === 'line') {
      const paint = this.paint as LinePaint;
      return typeof paint['line-color'] === 'string' ? true : false;
    } else if (this.type === 'fill') {
      const paint = this.paint as FillPaint;
      return typeof paint['fill-color'] === 'string' ? true : false;
    } else if (this.type === 'symbol') {
      const layout = this.layout as SymbolLayout;
      return typeof layout['icon-image'] === 'string' ? true : false;
    } else {
      return true;
    }
  }

  getCircleStyle() {
    let style = {};
    const paint = this.paint as CirclePaint;
    const strokeColor = paint['circle-stroke-color'];
    const backgroundColor = paint['circle-color'];
    if (strokeColor) {
      style = { border: `3px solid ${strokeColor}` };
    }
    if (backgroundColor) {
      style = { ...style, 'background-color': backgroundColor };
    }
    return style;
  }

  getLineStyle() {
    let style = {};
    const paint = this.paint as LinePaint;
    const lineColor = paint['line-color'] ?? '#000';
    const lineStyle = paint['line-dasharray'] ? 'dashed' : 'solid';
    style = { 'border-top': `3px ${lineStyle} ${lineColor}` };
    return style;
  }

  getFillStyle(color: string) {
    const paint = this.paint as FillPaint;
    if (color == 'rgba(0,0,0,0)') {
      color = '#ffffff';
    }
    return {
      'background-color': color,
      opacity: paint['fill-opacity'] ?? 1,
    };
  }

  singleMapSymbol(): boolean {
    const layout = this.layout as SymbolLayout;
    return typeof layout['icon-image'] === 'string' ? true : false;
  }

  getSymbol(iconImage?: string): string | undefined {
    let name = iconImage;
    if (!iconImage) {
      const symbolLayout = this.layout as SymbolLayout;
      name = symbolLayout['icon-image'] as string;
    }
    return this.mapSymbols?.find((symbol) => symbol.name === name)?.src;
  }

  getColorCategories() {
    const paint = this.paint as FillPaint;
    const fillColor = paint['fill-color'] as Expression;
    if (fillColor[0] === 'match') {
      return this.getMatchCategories(fillColor);
    } else {
      // logic for other expression types can go here
      return [];
    }
  }

  getSymbolCategories() {
    const layout = this.layout as SymbolLayout;
    const symbolExpression = layout['icon-image'] as Expression;
    if (symbolExpression[0] === 'match') {
      return this.getMatchCategories(symbolExpression);
    } else {
      // logic for other expression types can go here
      return [];
    }
  }

  getMatchCategories(expression: Expression): ColorData[] {
    const colors = expression.slice(2, expression.length - 1);
    const colorData: { label: string | number; value: string }[] = [];
    colors.map((currentValue, currentIndex) => {
      if (currentIndex % 2 === 0) {
        colorData.push({ label: currentValue, value: '' });
      } else {
        colorData[(currentIndex - 1) / 2].value = currentValue;
      }
    });
    return colorData;
  }

  getMatchField(): string {
    const paint = this.paint as FillPaint;
    const fillColor = paint['fill-color'] as Expression;
    if (fillColor && fillColor[0] === 'match') {
      return fillColor[1][1];
    } else {
      return '';
    }
  }
}
