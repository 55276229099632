import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {
  LngLat,
  Map,
  MapLayerMouseEvent,
  NavigationControl,
  SymbolLayout,
} from 'mapbox-gl';

import { MaterialModule } from '@material/material.module';

import { ConfigService } from '@core/services/config.service';
import { DataService } from '@core/services/data.service';
import { EnvironmentService } from '@core/services/environment.service';
import { MapConfig } from '@core/models/map-config.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { MapSymbol } from '@core/models/map-symbol.model';

import { LayerListComponent } from '../../components/layer-list/layer-list.component';
import { LayerListGroup } from '@core/models/layer-list-group.model';
import { SourceData } from '@core/models/spatial-data-source.model';
import { PopupComponent } from '../../components/popup/popup.component';
import {
  BiodiversityPointProperties,
  BiodiversityProperties,
  IdeaProperties,
  PopupData,
  TransectPointsProperties,
  TreeProperties,
} from '@core/models/popup.model';

@Component({
  selector: 'map-map',
  standalone: true,
  imports: [
    CommonModule,
    LayerListComponent,
    MaterialModule,
    NgxMapboxGLModule,
    PopupComponent,
  ],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  map!: Map;
  mapConfig!: MapConfig;
  token!: string;
  spatialData$: Observable<SourceData[]>;
  layerConfig: MapLayerConfig[];
  layerListGroup?: LayerListGroup[];
  mapSymbols?: MapSymbol[];
  selectedLngLat?: LngLat | null;
  selectedPoint?: GeoJSON.Feature<GeoJSON.Point> | null;
  selectedSymbol?: MapSymbol;
  popupData!: PopupData;

  constructor(
    private configService: ConfigService,
    private dataService: DataService,
    private environmentService: EnvironmentService
  ) {
    this.mapConfig = this.configService.config.map;
    this.token = this.environmentService.environment.mapbox.apiKey;
    this.spatialData$ = this.dataService.loadSpatialData();
    this.layerConfig = this.configService.config.mapLayers;
    this.layerListGroup = this.configService.config.layerListGroup;
    this.mapSymbols = this.configService.config.mapSymbols;
  }

  onMapLoad(mapInstance: Map): void {
    this.map = mapInstance;
    this.map.addControl(new NavigationControl());
    this.registerMapClickEvent();
    this.mapSymbols?.forEach((symbol: MapSymbol) => {
      this.map.loadImage(symbol.src, (error, image) => {
        if (error) throw error;
        if (image) {
          this.map.addImage(symbol.name, image);
        }
      });
    });
  }

  /**
   * Handle map click events to display popups
   */
  registerMapClickEvent(): void {
    const popupFields: { [key: string]: string[] } = {};
    /** find layers that have popups along with the name of the property names to display in the popup */
    const popupLayers = this.layerConfig
      .filter((lyr) => lyr.popups)
      .map((lyr) => {
        if (lyr.popups) {
          popupFields[lyr.id] = lyr.popups.popupProperties;
        }
        return lyr.id;
      });

    /** change mouse cursor when hovering on a layer with popups */
    this.map.on(
      'mouseenter',
      popupLayers,
      () => (this.map.getCanvas().style.cursor = 'pointer')
    );

    this.map.on(
      'mouseleave',
      popupLayers,
      () => (this.map.getCanvas().style.cursor = '')
    );
  }

  formatPopupData<T, K extends keyof T>(
    obj: T,
    titleField: K,
    descriptionField: K,
    imageField?: K
  ): any {
    let imageSrc;
    if (imageField) {
      imageSrc = obj[imageField];
    }
    return {
      title: obj[titleField] ?? '',
      description: obj[descriptionField] ?? '',
      imageSrc: imageSrc ?? '',
    };
  }

  onClick(evt: MapLayerMouseEvent) {
    if (evt.features) {
      if (
        evt.features[0].layer.id === 'ideas' ||
        evt.features[0].layer.id === 'observations'
      ) {
        this.popupData = this.formatPopupData(
          evt.features[0].properties as IdeaProperties,
          'Name',
          'Descriptio',
          'ImageName'
        ) as PopupData;
        const layout = evt.features[0].layer.layout as SymbolLayout;
        this.selectedSymbol = this.mapSymbols?.find((symbol) => {
          const icon = layout['icon-image'] as any;
          return symbol.name === icon.name;
        });
        this.selectedLngLat = null;
        this.selectedPoint = evt
          .features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
      } else if (evt.features[0].layer.id === 'trees') {
        this.popupData = this.formatPopupData(
          evt.features[0].properties as TreeProperties,
          'common_name',
          'age_group'
        ) as PopupData;
        this.selectedSymbol = this.mapSymbols?.find(
          (symbol) => symbol.name === 'tree'
        );
        this.selectedLngLat = null;
        this.selectedPoint = evt
          .features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
      } else if (evt.features[0].layer.id === 'biodiversity') {
        this.selectedPoint = null;
        this.selectedLngLat = evt.lngLat;
        this.selectedSymbol = undefined;
        const properties = evt.features[0].properties as BiodiversityProperties;
        this.popupData = {
          title: 'Biodiversity score',
          description: properties.BHP_Score.toString(),
        };
      } else if (evt.features[0].layer.id === 'transect-points') {
        this.selectedSymbol = undefined;
        this.selectedLngLat = null;
        this.selectedPoint = evt
          .features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
        const properties = evt.features[0]
          .properties as TransectPointsProperties;
        this.popupData = {
          title: `${properties.StopNo}. ${properties.StopName}`,
          description: properties.description,
          imageSrc: properties.image,
        };
      } else if (evt.features[0].layer.id === 'biodiversity-points') {
        this.selectedSymbol = undefined;
        this.selectedLngLat = null;
        this.selectedPoint = evt
          .features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
        const properties = evt.features[0]
          .properties as BiodiversityPointProperties;
        this.popupData = {
          title: properties.Name,
          description: `<b>Biodiversity Score: ${properties.BHP_Score}</b> <br> ${properties.Description} <br><span class="copyright">Image &#169; ${properties.copyright}</span>`,
          imageSrc: properties.image,
        };
      }
    }
  }
}
