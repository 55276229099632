import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Extent } from '@core/models/map-config.model';
import { LegendComponent } from '../legend/legend.component';
import { Map } from 'mapbox-gl';
import { LayerListGroup } from '@core/models/layer-list-group.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { MaterialModule } from '@material/material.module';
import { MapSymbol } from '@core/models/map-symbol.model';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
  selector: 'map-layer-list',
  standalone: true,
  imports: [CommonModule, LegendComponent, MaterialModule, TippyDirective],
  templateUrl: './layer-list.component.html',
  styleUrls: ['./layer-list.component.scss'],
})
export class LayerListComponent {
  @Input() categories?: LayerListGroup[];
  @Input() layers!: MapLayerConfig[];
  @Input() map!: Map;
  @Input() mapSymbols?: MapSymbol[];

  getLayers(catId?: string) {
    if (catId) {
      return this.layers.filter((l) => l.layerListGroup === catId).reverse();
    } else {
      return this.layers.reverse();
    }
  }

  toggleVisibility(layerId: string): void {
    const visibility = this.map.getLayoutProperty(layerId, 'visibility');
    const labelLayer = this.map.getLayer(`${layerId}-label`);
    if (visibility === 'visible') {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
      if (labelLayer) {
        this.map.setLayoutProperty(`${layerId}-label`, 'visibility', 'none');
      }
    } else {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
      if (labelLayer) {
        this.map.setLayoutProperty(`${layerId}-label`, 'visibility', 'visible');
      }
    }
  }

  zoomTo(extent: Extent): void {
    this.map.flyTo({
      zoom: extent.zoom,
      center: [extent.center[0], extent.center[1]],
    });
  }
}
