import { Injectable } from '@angular/core';
import { ConfigModel } from '@core/models/config.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService<T extends ConfigModel = ConfigModel> {
  static config: ConfigModel;

  static load(): Promise<ConfigModel> {
    return new Promise<ConfigModel>((resolve, reject) => {
      fetch('configuration/config.json')
        .then((response) => response.json())
        .then((config) => {
          ConfigService.config = config;
          return resolve(config);
        })
        .catch((error) => reject(error));
    });
  }

  get config(): ConfigModel {
    return ConfigService.config;
  }
}
