import { Injectable } from '@angular/core';
import { EnvironmentModel } from '@core/models/environment.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  static environment: EnvironmentModel;

  get environment(): EnvironmentModel {
    return environment;
  }
}
