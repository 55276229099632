<div class="popup" *ngIf="featureData">
  <div class="popup__header">
    <h1>{{ featureData.title }}</h1>
    <img
      *ngIf="featureSymbol"
      src="{{ featureSymbol.src }}"
      alt="{{ featureSymbol.displayName }}"
      matTooltip="{{ featureSymbol.displayName }}"
      matTooltipClass="popup__tooltip"
    />
  </div>
  <img
    class="popup__photo"
    *ngIf="featureData.imageSrc"
    src="{{ 'assets/photos/' + featureData.imageSrc + '.PNG' }}"
    alt=""
  />
  <div class="popup__content">
    <p [innerHTML]="featureData.description"></p>
  </div>
</div>
