<div class="map">
  <map-layer-list
    [categories]="layerListGroup"
    [layers]="layerConfig"
    [map]="map"
    [mapSymbols]="mapSymbols"
  ></map-layer-list>
  <mgl-map
    [style]="mapConfig.style"
    [zoom]="[mapConfig.zoom]"
    [center]="[mapConfig.center[0], mapConfig.center[1]]"
    [accessToken]="token"
    (mapLoad)="onMapLoad($event)"
  >
    <ng-container *ngIf="spatialData$ | async as spatialData">
      <ng-container *ngFor="let source of spatialData">
        <mgl-geojson-source
          id="{{ source.id }}-data"
          [data]="source.data"
        ></mgl-geojson-source>
      </ng-container>
      <ng-container *ngFor="let config of layerConfig">
        <mgl-layer
          [id]="config.id"
          [type]="config.type"
          [layout]="config.layout"
          [paint]="config.paint"
          (layerClick)="onClick($event)"
          source="{{ config.source }}-data"
        ></mgl-layer>
      </ng-container>
    </ng-container>
    <mgl-popup *ngIf="selectedPoint" [feature]="selectedPoint">
      <map-popup
        [featureData]="popupData"
        [featureSymbol]="selectedSymbol"
      ></map-popup>
    </mgl-popup>
    <mgl-popup *ngIf="selectedLngLat" [lngLat]="selectedLngLat">
      <map-popup
        [featureData]="popupData"
        [featureSymbol]="selectedSymbol"
      ></map-popup>
    </mgl-popup>
  </mgl-map>
</div>
