<div class="legend" [ngSwitch]="type">
  <ng-container *ngIf="!paintColorString(); else singleSymbol">
    <div
      *ngIf="type !== 'symbol'; else MultipleSymbols"
      [ngClass]="{ 'legend--row': getMatchField() === 'BHP_Score' }"
    >
      <div
        *ngFor="let color of getColorCategories()"
        class="legend__category"
        [ngClass]="{ 'legend__category--row': getMatchField() === 'BHP_Score' }"
      >
        <div
          *ngIf="getMatchField() === 'BHP_Score'"
          class="legend-item legend-item--fill legend-item--hex-outline"
        ></div>
        <div
          *ngSwitchCase="'fill'"
          class="legend-item legend-item--fill"
          [ngClass]="{ 'legend-item--hex': getMatchField() === 'BHP_Score' }"
          [ngStyle]="getFillStyle(color.value)"
        ></div>
        <p class="legend-item__description">{{ color.label }}</p>
      </div>
    </div>
    <ng-template #MultipleSymbols>
      <div
        *ngFor="let symbol of getSymbolCategories()"
        class="legend__category legend__category--symbol"
      >
        <img class="legend__symbol" [src]="getSymbol(symbol.value)" alt="" />
        <p class="legend-item__description">{{ symbol.label }}</p>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #singleSymbol>
    <div
      *ngSwitchCase="'line'"
      class="legend-item legend-item--line"
      [ngStyle]="getLineStyle()"
    ></div>
    <div
      *ngSwitchCase="'circle'"
      class="legend-item legend-item--circle"
      [ngStyle]="getCircleStyle()"
    ></div>
    <div *ngSwitchCase="'symbol'">
      <img
        class="legend__symbol"
        *ngIf="singleMapSymbol()"
        [src]="getSymbol()"
        alt=""
      />
    </div>
  </ng-template>
</div>
