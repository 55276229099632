import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, map, throwError, forkJoin } from 'rxjs';
import { FeatureCollection } from 'geojson';
import { ConfigService } from './config.service';
import {
  SourceData,
  SpatialDataSource,
} from '@core/models/spatial-data-source.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private spatialDataSources: SpatialDataSource[];

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {
    this.spatialDataSources = this.configService.config.spatialDataSources;
  }

  /**
   * Loads all spatial data
   * @returns SourceData[] Array of ids and geojson
   */
  loadSpatialData(): Observable<SourceData[]> {
    const requests = this.spatialDataSources.map(
      (dataSource: SpatialDataSource) =>
        this.httpClient.get<FeatureCollection>(dataSource.fileLocation).pipe(
          map((data) => {
            return { id: dataSource.id, data: data };
          })
        )
    );
    return forkJoin(requests).pipe(
      map((data: SourceData[]) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  /**
   * Error handler
   * @param err Http error response
   * @returns error
   */
  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
